
import Moderator from '@/models/bread/Moderator/series'

export default {
    key: 'Series',
    name: {
        singular: 'Series',
        plural: 'Series',
    },
    parents: [ ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}